




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import dayjs from 'dayjs'
import {Component, Watch, Vue} from 'vue-property-decorator'
import {Validations} from 'vuelidate-property-decorators'
import {validationMixin} from 'vuelidate'
import {
  required,
  maxLength,
  minValue,
  numeric,
  minLength,
  requiredIf,
  ValidationRule,
} from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/ProgramaticPointController'
import routeController, {Data} from '@/app/ui/controllers/RouteController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import ProgramTextInput from '../components/ProgramTextInput/index.vue'
import ProgramDropdown from '../components/ProgramDropdown/index.vue'
import ProgramDatePicker from '../components/ProgramDatePicker/index.vue'
import ProgramOptionRadio from '../components/ProgamOptionRadio/index.vue'
import Note from '@/app/ui/components/Note/Note.vue'
import {EventBus, EventBusConstants} from '@/app/infrastructures/misc'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import * as constantData from '@/app/infrastructures/misc/Constants/programaticPoint'
import {
  GroupOption,
  LabelValue,
} from '@/app/ui/components/OptionBox/interfaces'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import {PARCEL_POINT_NAME} from '@/app/infrastructures/misc/Constants/programaticPoint'
import useProgrammaticPoint from '@/app/ui/views/Saldo/ProgramaticPoint/useCase'
import ProgramCheckBox from '../components/ProgramCheckBox/index.vue'
import ProgramMultiTagTextInput from '../components/ProgramMultiTagTextInput/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import {PointPurpose} from '@/domain/entities/PointPurpose'
import {FormProgrammaticPoint} from '@/data/infrastructures/misc/interfaces/programmaticPoint'
import ProgramMultiSelect from '../components/ProgramMultiSelect/index.vue'

interface DropdownInterface {
  value: string | number
  label: string
  $isDisabled?: boolean
}

interface ValidationsInterface {
  form: {
    programName: {
      required: () => ValidationRule;
      maxLength: ValidationRule;
    }
    userTarget: {
      required: () => ValidationRule;
    }
    triggerBy: {
      required: () => ValidationRule;
    }
    scheduleDate: {
      required?: () => ValidationRule;
    }
    scheduleTime: {
      required?: () => ValidationRule;
    }
    purpose: Record<string, never>
    invoiceSource: {
      required: ValidationRule;
    }
    minimumTransaction: {
      required: ValidationRule;
      minValue: ValidationRule;
    }
    paymentMethod: {
      required: ValidationRule;
      minLength: ValidationRule;
    }
    // pointAmount: {
    //   required: ValidationRule;
    //   minValue: ValidationRule;
    //   numeric: () => ValidationRule;
    // }
    percentOfTransaction: {
      required: ValidationRule;
      minValue: ValidationRule;
      numeric: () => ValidationRule;
    }
    maxAmountGiven: {
      required: ValidationRule;
      minValue: ValidationRule;
      numeric: () => ValidationRule;
    }
    expiredDate: {
      required: ValidationRule;
    }
    expiryDay: {
      required: ValidationRule;
      minValue: ValidationRule;
    }
    // limitPerDay: {
    //   required: () => ValidationRule;
    //   minValue: ValidationRule;
    // }
    // limitPerUser: {
    //   required: () => ValidationRule;
    //   minValue: ValidationRule;
    // }
    budgetSource: {
      required: () => ValidationRule;
    }
    budgetAmount: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    budgetSourceOther: {
      required: ValidationRule;
    }
    startDate: {
      required?: () => ValidationRule;
    }
    startTime: {
      required?: () => ValidationRule;
    }
    endDate: {
      required?: () => ValidationRule;
    }
    endTime: {
      required?: () => ValidationRule;
    }
    fileUsers: {
      name: {
        required: ValidationRule;
      }
    }
    membershipLevel: {
      required: ValidationRule
    }
    totalAmountTransaction: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalAmountTransactionTime: {
      required: ValidationRule;
    }
    totalNumberTransaction: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalNumberTransactionTime: {
      required: ValidationRule;
    }
    logicType: {
      required: ValidationRule
    }
    statusType: {
      required: ValidationRule
    }
    shipmentType: {
      required: ValidationRule
    }
    productType: {
      required: ValidationRule
    }
    minimumAmount: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    deviceIdLimitCustomer: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValue: {
      required: () => ValidationRule
    }
    pointValueAmountForInviter: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValueAmountForInviterDisburseTo: {
      required: ValidationRule
    }
    pointValueAmountForInviterApprovalType: {
      required: ValidationRule
    }
    pointValueAmountForInviterDisburseType: {
      required: ValidationRule
    }
    pointValueAmountForInviterDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValueAmountForInviterDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValueAmountForInvitee: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValueAmountForInviteeDisburseTo: {
      required: ValidationRule
    }
    pointValueAmountForInviteeApprovalType: {
      required: ValidationRule
    }
    pointValueAmountForInviteeDisburseType: {
      required: ValidationRule
    }
    pointValueAmountForInviteeDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValueAmountForInviteeDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValueAmountForGeneral: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValueAmountForGeneralDisburseTo: {
      required: ValidationRule
    }
    pointValueAmountForGeneralApprovalType: {
      required: ValidationRule
    }
    pointValueAmountForGeneralDisburseType: {
      required: ValidationRule
    }
    pointValueAmountForGeneralDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValueAmountForGeneralDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValuePercentageForInviter: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForInviterMax: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForInviterDisburseTo: {
      required: ValidationRule
    }
    pointValuePercentageForInviterApprovalType: {
      required: ValidationRule
    }
    pointValuePercentageForInviterDisburseType: {
      required: ValidationRule
    }
    pointValuePercentageForInviterDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValuePercentageForInviterDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValuePercentageForInvitee: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForInviteeMax: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForInviteeDisburseTo: {
      required: ValidationRule
    }
    pointValuePercentageForInviteeApprovalType: {
      required: ValidationRule
    }
    pointValuePercentageForInviteeDisburseType: {
      required: ValidationRule
    }
    pointValuePercentageForInviteeDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValuePercentageForInviteeDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    pointValuePercentageForGeneral: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForGeneralMax: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    pointValuePercentageForGeneralDisburseTo: {
      required: ValidationRule
    }
    pointValuePercentageForGeneralApprovalType: {
      required: ValidationRule
    }
    pointValuePercentageForGeneralDisburseType: {
      required: ValidationRule
    }
    pointValuePercentageForGeneralDisburseTypeShipmentStatus: {
      required: ValidationRule
    }
    pointValuePercentageForGeneralDisburseTypeSpecificDate: {
      required: ValidationRule
    }
    totalNumberDisbursePerProgram: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalAmountDisbursePerProgram: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalNumberDisbursePerUser: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
    totalAmountDisbursePerUser: {
      required: ValidationRule;
      minValue: ValidationRule | boolean;
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Modal,
    ProgramTextInput,
    ProgramDropdown,
    ProgramDatePicker,
    ProgramOptionRadio,
    OptionBox,
    LoadingOverlay,
    RadioButton,
    Note,
    Toggle,
    ModalAction,
    ProgramCheckBox,
    ProgramMultiTagTextInput,
    IconWarningTriangle,
    ProgramMultiSelect,
  },
})
export default class CreateProgram extends Vue {
  controller = controller
  useActionProgrammaticPoint = useProgrammaticPoint()
  routeController = routeController
  maxCount = 30
  form: FormProgrammaticPoint = {
    programName: '',
    userTarget: <LabelValue<constantData.UserType> | null>null,
    fileUsers: new File([], ''),
    triggerBy: <LabelValue<string> | null>null,
    routes: <Data[]>[],
    scheduleDate: '',
    scheduleTime: '',
    invoiceSource: <Array<string>>[],
    paymentMethod: <Array<string>>[],
    minimumTransaction: 0,
    purpose: <Array<string>>[],
    deviceIdLimitCustomer: null,
    pointValueType: 'fixed',
    // pointAmount: 0,
    percentOfTransaction: 0,
    maxAmountGiven: <number | null>null,
    pointExpirationType: 'days',
    expiredDate: <string | null>null,
    expiryDay: 0,
    // limitPerDay: 0,
    // limitPerUser: 0,
    budgetSource: <LabelValue<string> | null>null,
    budgetAmount: <null | number | undefined>null,
    budgetSourceOther: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    membershipLevel: <{ label: string, value: number }[]>[],
    extraRulesActive: <boolean>false,
    totalAmountTransaction: <null | number | undefined>null,
    totalAmountTransactionTime: <LabelValue<string> | null>null,
    totalNumberTransaction: <null | number | undefined>null,
    totalNumberTransactionTime: <LabelValue<string> | null>null,
    logicType: '',
    statusType: <Array<string>>[],
    shipmentType: <Array<string>>[],
    productType: <Array<string>>[],
    minimumAmount: <null | number | undefined>null,
    pointValue: <LabelValue<string | number> | null>null,
    pointValueAmountForInviter: <null | number | undefined>null,
    pointValueAmountForInviterDisburseTo: <LabelValue<string> | null>null,
    pointValueAmountForInviterApprovalType: <LabelValue<string> | null>null,
    pointValueAmountForInviterDisburseType: <LabelValue<string> | null>null,
    pointValueAmountForInviterDisburseTypeShipmentStatus: <LabelValue<string> | null>null,
    pointValueAmountForInviterDisburseTypeSpecificDate: <LabelValue<number> | null>null,
    pointValueAmountForInvitee: <null | number | undefined>null,
    pointValueAmountForInviteeDisburseTo: <LabelValue<string> | null>null,
    pointValueAmountForInviteeApprovalType: <LabelValue<string> | null>null,
    pointValueAmountForInviteeDisburseType: <LabelValue<string> | null>null,
    pointValueAmountForInviteeDisburseTypeShipmentStatus: <LabelValue<string> | null>null,
    pointValueAmountForInviteeDisburseTypeSpecificDate: <LabelValue<number> | null>null,
    pointValueAmountForGeneral: <null | number | undefined>null,
    pointValueAmountForGeneralDisburseTo: <LabelValue<string> | null>null,
    pointValueAmountForGeneralApprovalType: <LabelValue<string> | null>null,
    pointValueAmountForGeneralDisburseType: <LabelValue<string> | null>null,
    pointValueAmountForGeneralDisburseTypeShipmentStatus: <LabelValue<string> | null>null,
    pointValueAmountForGeneralDisburseTypeSpecificDate: <LabelValue<number> | null>null,
    pointValuePercentageForInviter: <null | number | undefined>null,
    pointValuePercentageForInviterMax: <null | number | undefined>null,
    pointValuePercentageForInviterDisburseTo: <LabelValue<string> | null>null,
    pointValuePercentageForInviterApprovalType: <LabelValue<string> | null>null,
    pointValuePercentageForInviterDisburseType: <LabelValue<string> | null>null,
    pointValuePercentageForInviterDisburseTypeShipmentStatus: <LabelValue<string> | null>null,
    pointValuePercentageForInviterDisburseTypeSpecificDate: <LabelValue<number> | null>null,
    pointValuePercentageForInvitee: <null | number | undefined>null,
    pointValuePercentageForInviteeMax: <null | number | undefined>null,
    pointValuePercentageForInviteeDisburseTo: <LabelValue<string> | null>null,
    pointValuePercentageForInviteeApprovalType: <LabelValue<string> | null>null,
    pointValuePercentageForInviteeDisburseType: <LabelValue<string> | null>null,
    pointValuePercentageForInviteeDisburseTypeShipmentStatus: <LabelValue<string> | null>null,
    pointValuePercentageForInviteeDisburseTypeSpecificDate: <LabelValue<number> | null>null,
    pointValuePercentageForGeneral: <null | number | undefined>null,
    pointValuePercentageForGeneralMax: <null | number | undefined>null,
    pointValuePercentageForGeneralDisburseTo: <LabelValue<string> | null>null,
    pointValuePercentageForGeneralApprovalType: <LabelValue<string> | null>null,
    pointValuePercentageForGeneralDisburseType: <LabelValue<string> | null>null,
    pointValuePercentageForGeneralDisburseTypeShipmentStatus: <LabelValue<string> | null>null,
    pointValuePercentageForGeneralDisburseTypeSpecificDate: <LabelValue<number> | null>null,
    pointDisburseType: <LabelValue<string> | null>null,
    shipmentStatus: <LabelValue<string> | null>null,
    specificDate: <LabelValue<string | number> | null>null,
    pointDisburseLimitPerProgram: <LabelValue<string> | null>null,
    totalNumberDisbursePerProgram: <null | number | undefined>null,
    totalAmountDisbursePerProgram: <null | number | undefined>null,
    pointDisburseLimitPerUser: <LabelValue<string> | null>null,
    totalNumberDisbursePerUser: <null | number | undefined>null,
    totalAmountDisbursePerUser: <null | number | undefined>null,
    pointPurposeState: <{ checked: boolean; value: number; label: string } []>[],
    isPercentage: false,
    isExactDate: false
  }
  pointPurposeChanged = false
  tagChanged = {
    statusType: false,
    shipmentType: false,
    productType: false,
  }
  successModal = false
  showModalConfirm = false
  todayDate = dayjs().format('YYYY-MM-DD')
  disableLimitUser = false
  constants = constantData
  labelOfPercentagePoint = 'Percentage of Total Transaction'
  budgetAmountActive = false
  budgetAmountDisabledOnEdit = false
  extraRulesActive = false
  deviceIdLimitCustomerActive = false
  extraRulesDisabledOnEdit = false
  totalAmountTransactionActive = false
  totalNumberTransactionActive = false
  pointValueAmountForInviterActive = false
  pointValueAmountForInviteeActive = false
  pointValueAmountForGeneralActive = false
  pointValuePercentageForInviterActive = false
  pointValuePercentageForInviterMaxActive = false
  pointValuePercentageForInviteeActive = false
  pointValuePercentageForInviteeMaxActive = false
  pointValuePercentageForGeneralActive = false
  pointValuePercentageForGeneralMaxActive = false
  totalNumberDisbursePerProgramActive = false
  totalAmountDisbursePerProgramActive = false
  totalNumberDisbursePerUserActive = false
  totalAmountDisbursePerUserActive = false

  created(): void {
    routeController.getCityList()
    controller.getTriggers()
    controller.getPointPurposes()
    controller.getPaymentMethodList()
    controller.getMembershipLevelList()

    EventBus.$on(EventBusConstants.SAVE_PROGRAM_SUCCESS, () => {
      this.successModal = true
    })
  }

  @Watch('controller.pointPurposes', {deep: true})
  onPointPurposesChanged(newVal: PointPurpose[]): void {
    this.form.pointPurposeState = <{ checked: boolean, value: number, label: string }[]>newVal.map((item) => {
      return {
        checked: false,
        value: 0,
        label: item.id?.toLowerCase(),
      }
    })
  }

  @Watch('form.triggerBy', {deep: true})
  onTriggerChanged(
    trigger: DropdownInterface,
    oldTrigger: DropdownInterface
  ): void {
    // Select Complete Profile
    // if (
    //   trigger &&
    //   trigger.value === constantData.TriggerType.COMPLETE_PROFILE
    // ) {
    //   this.form.limitPerUser = 1
    //   this.disableLimitUser = true
    // }
    // // UnSelect Complete Profile
    // if (
    //   oldTrigger &&
    //   oldTrigger.value === constantData.TriggerType.COMPLETE_PROFILE
    // ) {
    //   this.form.limitPerUser = 0
    //   this.disableLimitUser = false
    // }

    const triggers = [
      constantData.TriggerType.PAID_ALL,
      constantData.TriggerType.SHIPMENT_PAID_BY_CITY,
    ]
    if (
      triggers.includes(<constantData.TriggerType>oldTrigger?.value || '') &&
      !triggers.includes(<constantData.TriggerType>trigger?.value || '')
    ) {
      this.form.pointValueType = constantData.PointValueType.FIXED
    }

    // if (this.isMemberUser) {
    //   this.form.pointValueType = constantData.PointValueType.PERCENTAGE
    // }

    if (trigger.value === constantData.TriggerType.TRANSACTION_DONE) {
      this.form.statusType = ['POD']
      this.form.shipmentType = ['AG', 'AD', 'ARA', 'ARB', 'AP', 'AS', 'AO']
      this.form.productType = ['REGPACK', 'JAGOPACK', 'BOSSPACK', 'INTERPACK', 'BIGPACK']
      this.form.minimumAmount = 0
    } else {
      this.form.statusType = []
      this.form.shipmentType = []
      this.form.productType = []
      this.form.minimumAmount = null
    }
  }

  @Watch('form.userTarget', {deep: true})
  onUserTargetChanged(newVal: Record<string, string | boolean> | null): void {
    // if (newVal && newVal['value'] === constantData.UserType.MEMBERSHIP) {
    //   this.form.pointValueType = constantData.PointValueType.PERCENTAGE
    //   this.labelOfPercentagePoint = 'Percentage of Total Shipping Fee'
    // } else {
    //   this.labelOfPercentagePoint = 'Percentage of Total Transaction'
    // }

    if (
      newVal &&
      newVal['value'] === constantData.UserType.LOGIN &&
      !this.triggerParcelPoint
    ) {
      this.form.triggerBy = null
    }

    if (newVal && newVal['value'] === constantData.UserType.MEMBERSHIP) {
      this.form.membershipLevel = controller.membershipLevel
    } else {
      this.form.membershipLevel = []
    }
  }

  @Validations()
  validations(): ValidationsInterface {
    let validate = {
      form: {
        programName: {required, maxLength: maxLength(this.maxCount)},
        userTarget: {required},
        triggerBy: {required},
        scheduleDate: {},
        scheduleTime: {},
        purpose: {},
        invoiceSource: {
          required: requiredIf(() => this.triggedByInvoicePaidAll),
        },
        minimumTransaction: {
          required: requiredIf(
            () => this.triggedByInvoicePaidAll || this.triggedByShipmentPaidCity
          ),
          minValue: minValue(
            this.triggedByInvoicePaidAll || this.triggedByShipmentPaidCity
              ? 1
              : 0
          ),
        },
        paymentMethod: {
          required: requiredIf(
            () => this.triggedByInvoicePaidAll || this.triggedByShipmentPaidCity
          ),
          minLength: minLength(
            this.triggedByInvoicePaidAll || this.triggedByShipmentPaidCity
              ? 1
              : 0
          ),
        },
        // pointAmount: {
        //   required: requiredIf(
        //     () => this.form.pointValueType === constantData.PointValueType.FIXED
        //   ),
        //   minValue: minValue(
        //     this.form.pointValueType === constantData.PointValueType.FIXED
        //       ? 1
        //       : 0
        //   ),
        //   numeric,
        // },
        percentOfTransaction: {
          required: requiredIf(
            () =>
              this.form.pointValueType ===
              constantData.PointValueType.PERCENTAGE
          ),
          minValue: minValue(
            this.form.pointValueType === constantData.PointValueType.PERCENTAGE
              ? 1
              : 0
          ),
          numeric,
        },
        maxAmountGiven: {
          required: requiredIf(
            () =>
              this.form.pointValueType ===
              constantData.PointValueType.PERCENTAGE
          ),
          minValue: minValue(
            this.form.pointValueType === constantData.PointValueType.PERCENTAGE
              ? 1
              : 0
          ),
          numeric,
        },
        expiredDate: {
          required: requiredIf(
            () =>
              this.form.pointExpirationType === 'exact-date'
          ),
        },
        expiryDay: {
          required: requiredIf(
            () =>
              this.form.pointExpirationType === 'days'
          ),
          minValue: minValue(
            this.form.pointExpirationType === 'days'
              ? 1
              : 0
          ),
        },
        // limitPerDay: { required, minValue: minValue(1) },
        // limitPerUser: {
        //   required,
        //   minValue: minValue(1),
        // },
        budgetSource: {required},
        budgetAmount: {
          required: requiredIf(
            () => this.budgetAmountActive && !this.isTriggeredByOnSchedule
          ),
          minValue:
            this.budgetAmountActive && !this.isTriggeredByOnSchedule
              ? minValue(1)
              : true,
        },
        budgetSourceOther: {
          required: requiredIf(
            () => this.form.budgetSource?.value === 'OTHERS'
          ),
        },
        startDate: {},
        startTime: {},
        endDate: {},
        endTime: {},
        fileUsers: {
          name: {
            required: requiredIf(
              () =>
                this.form.userTarget?.value === constantData.UserType.SELECTED
            ),
          },
        },
        membershipLevel: {
          required: requiredIf(
            () => this.isMemberUser
          ),
        },
        statusType: {
          required: requiredIf(
            () => this.triggedByTransactionDone
          ),
        },
        shipmentType: {
          required: requiredIf(
            () => this.triggedByTransactionDone
          ),
        },
        productType: {
          required: requiredIf(
            () => this.triggedByTransactionDone
          ),
        },
        minimumAmount: {
          required: requiredIf(
            () => this.triggedByTransactionDone
          ),
          minValue:
            this.triggedByTransactionDone
              ? minValue(0)
              : true,
        },
        totalAmountTransaction: {
          required: requiredIf(
            () => this.extraRulesTotalAmountValidation
          ),
          minValue:
            this.extraRulesTotalAmountValidation
              ? minValue(1)
              : true,
        },
        totalAmountTransactionTime: {
          required: requiredIf(
            () => this.extraRulesTotalAmountValidation
          )
        },
        totalNumberTransaction: {
          required: requiredIf(
            () => this.extraRulesTotalNumberValidation
          ),
          minValue:
            this.extraRulesTotalNumberValidation
              ? minValue(1)
              : true,
        },
        totalNumberTransactionTime: {
          required: requiredIf(
            () => this.extraRulesTotalNumberValidation
          )
        },
        logicType: {
          required: requiredIf(
            () => this.totalAmountTransactionActive && this.totalNumberTransactionActive
          )
        },
        deviceIdLimitCustomer: {
          required: requiredIf(
            () => this.deviceIdLimitCustomerActive
          ),
          minValue:
            this.deviceIdLimitCustomerActive
              ? minValue(1)
              : true,
        },
        pointValue: {required},
        pointValueAmountForInviter: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation
          ),
          minValue:
            this.pointValueAmountForInviterValidation
              ? minValue(1)
              : true,
        },
        pointValueAmountForInviterDisburseTo: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation
          )
        },
        pointValueAmountForInviterApprovalType: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation
          )
        },
        pointValueAmountForInviterDisburseType: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation
          )
        },
        pointValueAmountForInviterDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation && this.pointValueAmountForInviterDisburseTypeAfterFirstTransaction
          )
        },
        pointValueAmountForInviterDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValueAmountForInviterValidation && this.pointValueAmountForInviterDisburseTypeSpecificDateEveryMonth
          )
        },
        pointValueAmountForInvitee: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation
          ),
          minValue:
            this.pointValueAmountForInviteeValidation
              ? minValue(1)
              : true,
        },
        pointValueAmountForInviteeDisburseTo: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation
          )
        },
        pointValueAmountForInviteeApprovalType: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation
          )
        },
        pointValueAmountForInviteeDisburseType: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation
          )
        },
        pointValueAmountForInviteeDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation && this.pointValueAmountForInviteeDisburseTypeAfterFirstTransaction
          )
        },
        pointValueAmountForInviteeDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValueAmountForInviteeValidation && this.pointValueAmountForInviteeDisburseTypeSpecificDateEveryMonth
          )
        },
        pointValueAmountForGeneral: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation
          ),
          minValue:
            this.pointValueAmountForGeneralValidation
              ? minValue(1)
              : true,
        },
        pointValueAmountForGeneralDisburseTo: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation
          )
        },
        pointValueAmountForGeneralApprovalType: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation
          )
        },
        pointValueAmountForGeneralDisburseType: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation
          )
        },
        pointValueAmountForGeneralDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation && this.pointValueAmountForGeneralDisburseTypeAfterFirstTransaction
          )
        },
        pointValueAmountForGeneralDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValueAmountForGeneralValidation && this.pointValueAmountForGeneralDisburseTypeSpecificDateEveryMonth
          )
        },
        pointValuePercentageForInviter: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterValidation
          ),
          minValue:
            this.pointValuePercentageForInviterValidation
              ? minValue(1)
              : true,
        },
        pointValuePercentageForInviterMax: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterMaxActive
          ),
          minValue:
            this.pointValuePercentageForInviterMaxActive
              ? minValue(1)
              : true,
        },
        pointValuePercentageForInviterDisburseTo: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterActive
          )
        },
        pointValuePercentageForInviterApprovalType: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterActive
          )
        },
        pointValuePercentageForInviterDisburseType: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterActive
          )
        },
        pointValuePercentageForInviterDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterActive && this.pointValuePercentageForInviterDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForInviterDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValuePercentageForInviterActive && this.pointValuePercentageForInviterDisburseTypeSpecificDateEveryMonth
          )
        },
        pointValuePercentageForInvitee: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeValidation
          ),
          minValue:
            this.pointValuePercentageForInviteeValidation
              ? minValue(1)
              : true,
        },
        pointValuePercentageForInviteeMax: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeMaxActive
          ),
          minValue:
            this.pointValuePercentageForInviteeMaxActive
              ? minValue(1)
              : true,
        },
        pointValuePercentageForInviteeDisburseTo: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeActive
          )
        },
        pointValuePercentageForInviteeApprovalType: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeActive
          )
        },
        pointValuePercentageForInviteeDisburseType: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeActive
          )
        },
        pointValuePercentageForInviteeDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeActive && this.pointValuePercentageForInviteeDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForInviteeDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValuePercentageForInviteeActive && this.pointValuePercentageForInviteeDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForGeneral: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralValidation
          ),
          minValue:
            this.pointValuePercentageForGeneralValidation
              ? minValue(1)
              : true,
        },
        pointValuePercentageForGeneralMax: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralMaxActive
          ),
          minValue:
            this.pointValuePercentageForGeneralMaxActive
              ? minValue(1)
              : true,
        },
        pointValuePercentageForGeneralDisburseTo: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralActive
          )
        },
        pointValuePercentageForGeneralApprovalType: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralActive
          )
        },
        pointValuePercentageForGeneralDisburseType: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralActive
          )
        },
        pointValuePercentageForGeneralDisburseTypeShipmentStatus: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralActive && this.pointValuePercentageForGeneralDisburseTypeAfterFirstTransaction
          )
        },
        pointValuePercentageForGeneralDisburseTypeSpecificDate: {
          required: requiredIf(
            () => this.pointValuePercentageForGeneralActive && this.pointValuePercentageForGeneralDisburseTypeSpecificDateEveryMonth
          )
        },
        totalNumberDisbursePerProgram: {
          required: requiredIf(
            () => (this.totalNumberDisbursePerProgramActive || !this.totalAmountDisbursePerProgramActive) && this.form.pointDisburseLimitPerProgram
          ),
          minValue:
            (this.totalNumberDisbursePerProgramActive || !this.totalAmountDisbursePerProgramActive) && this.form.pointDisburseLimitPerProgram
              ? minValue(1)
              : true,
        },
        totalAmountDisbursePerProgram: {
          required: requiredIf(
            () => (this.totalAmountDisbursePerProgramActive || !this.totalNumberDisbursePerProgramActive) && this.form.pointDisburseLimitPerProgram
          ),
          minValue:
            (this.totalAmountDisbursePerProgramActive || !this.totalNumberDisbursePerProgramActive) && this.form.pointDisburseLimitPerProgram
              ? minValue(1)
              : true,
        },
        totalNumberDisbursePerUser: {
          required: requiredIf(
            () => (this.totalNumberDisbursePerUserActive || !this.totalAmountDisbursePerUserActive) && this.form.pointDisburseLimitPerUser
          ),
          minValue:
            (this.totalNumberDisbursePerUserActive || !this.totalAmountDisbursePerUserActive) && this.form.pointDisburseLimitPerUser
              ? minValue(1)
              : true,
        },
        totalAmountDisbursePerUser: {
          required: requiredIf(
            () => (this.totalAmountDisbursePerUserActive || !this.totalNumberDisbursePerUserActive) && this.form.pointDisburseLimitPerUser
          ),
          minValue:
            (this.totalAmountDisbursePerUserActive || !this.totalNumberDisbursePerUserActive) && this.form.pointDisburseLimitPerUser
              ? minValue(1)
              : true,
        },
      },
    }

    if (this.form.triggerBy?.value === constantData.TriggerType.ON_SCHEDULE) {
      validate = {
        form: {
          ...validate.form,
          scheduleDate: {required},
          scheduleTime: {required},
        },
      }
    } else {
      validate = {
        form: {
          ...validate.form,
          startDate: {required},
          startTime: {required},
          endDate: {required},
          endTime: {required},
        },
      }
    }

    return validate
  }

  private eventTargetUserType(): void {
    this.form.invoiceSource = []
    this.form.triggerBy = null
    this.form.fileUsers = new File([], '')
    this.resetConditionAffectByTrigger()
  }

  get getOptionsTriggeredInvoicePaidAll(): GroupOption<string, string>[] {
    return this.isMemberUser
      ? this.constants.INVOICE_SOURCE_MEMBERSHIP
      : this.constants.INVOICE_SOURCE
  }

  get triggerOptionsNew(): DropdownInterface[] {
    if (this.isUserTypeSelectedUser || this.isMemberUser) {
      return controller.triggerData.map(trigger => ({
        ...trigger,
        $isDisabled: trigger.value !== constantData.TriggerType.TRANSACTION_DONE
      }))
    } else if (this.isUserTypeAllLogin) {
      return controller.triggerData.map(trigger => ({
        ...trigger,
        $isDisabled: !this.useActionProgrammaticPoint.isParcelPointTrigger(
          <constantData.TriggerType>trigger.value
        )
      }))
    } else {
      return controller.triggerData.map(trigger => {
        if (trigger.value === constantData.TriggerType.COMPLETE_PROFILE) {
          return {
            ...trigger,
            $isDisabled: true,
          }
        }
        return {
          ...trigger,
          $isDisabled: !this.useActionProgrammaticPoint.isParcelPointTrigger(
            <constantData.TriggerType>trigger.value
          ),
        }
      })
    }
  }

  get getConditionForParcelPoint(): boolean {
    return (
      (this.form.userTarget &&
        this.useActionProgrammaticPoint.isParcelPointUserTarget(
          this.form.userTarget.value
        )) ||
      this.triggerParcelPoint
    )
  }

  get labelPointValue(): string {
    if (this.getConditionForParcelPoint) {
      return PARCEL_POINT_NAME
    }
    return 'Saldo Point'
  }

  get labelPointExpiration(): string {
    if (this.getConditionForParcelPoint) {
      return PARCEL_POINT_NAME
    }
    return 'Point'
  }

  get routeOptions(): Data[] {
    return routeController.cityData
  }

  get attachmentName(): string | null {
    return this.form.fileUsers ? this.form.fileUsers.name : null
  }

  get pointPurposeOptions(): Array<LabelValue<string | undefined>> {
    return controller.pointPurposes.map(item => ({
      label: item.name || '',
      value: item.id,
    }))
  }

  get paymentMethodList(): { label: string; value: string }[] {
    return controller.paymentMethods.map(item => ({
      label: item,
      value: item,
    }))
  }

  get extraRulesTotalAmountValidation(): boolean {
    if (this.extraRulesActive) {
      if (this.totalAmountTransactionActive && !this.totalNumberTransactionActive) {
        return true
      } else if (this.totalAmountTransactionActive && this.totalNumberTransactionActive) {
        return true
      } else if (!this.totalAmountTransactionActive && this.totalNumberTransactionActive) {
        return false
      }
      return true
    }
    return false
  }

  get extraRulesTotalNumberValidation(): boolean {
    if (this.extraRulesActive) {
      if (this.totalNumberTransactionActive && !this.totalAmountTransactionActive) {
        return true
      } else if (this.totalNumberTransactionActive && this.totalAmountTransactionActive) {
        return true
      } else if (!this.totalNumberTransactionActive && this.totalAmountTransactionActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValueAmountForInviterValidation(): boolean {
    if (this.pointValueAmount) {
      if (this.pointValueAmountForInviterActive && !this.pointValueAmountForInviteeActive && !this.pointValueAmountForGeneralActive) {
        return true
      } else if (this.pointValueAmountForInviterActive && this.pointValueAmountForInviteeActive && !this.pointValueAmountForGeneralActive) {
        return true
      } else if (!this.pointValueAmountForInviterActive && this.pointValueAmountForInviteeActive && !this.pointValueAmountForGeneralActive) {
        return false
      } else if (!this.pointValueAmountForInviterActive && !this.pointValueAmountForInviteeActive && this.pointValueAmountForGeneralActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValueAmountForInviteeValidation(): boolean {
    if (this.pointValueAmount) {
      if (this.pointValueAmountForInviteeActive && !this.pointValueAmountForInviterActive && !this.pointValueAmountForGeneralActive) {
        return true
      } else if (this.pointValueAmountForInviteeActive && this.pointValueAmountForInviterActive && !this.pointValueAmountForGeneralActive) {
        return true
      } else if (!this.pointValueAmountForInviteeActive && this.pointValueAmountForInviterActive && !this.pointValueAmountForGeneralActive) {
        return false
      } else if (!this.pointValueAmountForInviteeActive && !this.pointValueAmountForInviterActive && this.pointValueAmountForGeneralActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValueAmountForGeneralValidation(): boolean {
    if (this.pointValueAmount) {
      if (this.pointValueAmountForGeneralActive) {
        return true
      } else return false
    }
    return false
  }

  get isInviteAndInviterPercentageChecked(): boolean {
    return this.pointValuePercentageForInviterActive && this.pointValuePercentageForInviteeActive
  }

  get isInviteAndInviterAmountChecked(): boolean {
    return this.pointValueAmountForInviterActive && this.pointValueAmountForInviteeActive
  }

  get isValidCheckedInviteInviter(): boolean {
    if (this.pointValuePercentageForGeneralActive) {
      return true
    }

    if (this.pointValuePercentage && (this.pointValuePercentageForInviterActive || this.pointValuePercentageForInviteeActive)) {
      return this.isInviteAndInviterPercentageChecked
    }

    if (this.pointValueAmount && (this.pointValueAmountForInviterActive || this.pointValueAmountForInviteeActive)) {
      return this.isInviteAndInviterAmountChecked
    }

    return !this.pointValuePercentageForGeneralActive;
  }

  get isValidPointAmountInviterPointDisburseType(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviterDisburseType?.$dirty && !this.$v.form.pointValueAmountForInviterDisburseType?.required
  }

  get isValidPointAmountInviterPointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviterDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValueAmountForInviterDisburseTypeShipmentStatus?.required
  }

  get isValidPointAmountInviterPointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValueAmountForInviterDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValueAmountForInviterDisburseTypeSpecificDate?.required
  }

  get isValidPointPercentageInviterPointDisburseType(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviterDisburseType?.$dirty && !this.$v.form.pointValuePercentageForInviterDisburseType?.required
  }

  get isValidPointPercentageInviterPointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviterDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValuePercentageForInviterDisburseTypeShipmentStatus?.required
  }

  get isValidPointPercentageInviterPointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValuePercentageForGeneralDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValuePercentageForInviterDisburseTypeSpecificDate?.required
  }

  get isValidPointPercentageInviteePointDisburseType(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviteeDisburseType?.$dirty && !this.$v.form.pointValuePercentageForInviteeDisburseType?.required
  }

  get isValidPointPercentageInviteePointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviteeDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValuePercentageForInviteeDisburseTypeShipmentStatus?.required
  }

  get isValidPointPercentageInviteePointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValuePercentageForInviteeDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValuePercentageForInviteeDisburseTypeSpecificDate?.required
  }

  get isValidPointPercentageGeneralPointDisburseType(): boolean | undefined {
    return this.$v.form.pointValuePercentageForGeneralDisburseType?.$dirty && !this.$v.form.pointValuePercentageForGeneralDisburseType?.required
  }

  get isValidPointPercentageGeneralPointDisburseTypeShipmentStatus(): boolean | undefined {
    return this.$v.form.pointValuePercentageForGeneralDisburseTypeShipmentStatus?.$dirty && !this.$v.form.pointValuePercentageForGeneralDisburseTypeShipmentStatus?.required
  }

  get isValidPointPercentageGeneralPointDisburseTypeSpecificDate(): boolean | undefined {
    return this.$v.form.pointValuePercentageForGeneralDisburseTypeSpecificDate?.$dirty && !this.$v.form.pointValuePercentageForGeneralDisburseTypeSpecificDate?.required
  }

  get isValidPointAmountInviter(): boolean | undefined {
    return ((this.$v.form.pointValueAmountForInviter?.$dirty && !this.$v.form.pointValueAmountForInviter?.required) || !this.$v.form.pointValueAmountForInviter?.minValue) || (this.$v.form.pointValueAmountForInviterDisburseTo?.$dirty && !this.$v.form.pointValueAmountForInviterDisburseTo?.required) || (this.$v.form.pointValueAmountForInviterApprovalType?.$dirty && !this.$v.form.pointValueAmountForInviterApprovalType?.required)
  }

  get isValidPointAmountInvitee(): boolean | undefined {
    return ((this.$v.form.pointValueAmountForInvitee?.$dirty && !this.$v.form.pointValueAmountForInvitee?.required) || !this.$v.form.pointValueAmountForInvitee?.minValue) || (this.$v.form.pointValueAmountForInviteeDisburseTo?.$dirty && !this.$v.form.pointValueAmountForInviteeDisburseTo?.required) || (this.$v.form.pointValueAmountForInviteeApprovalType?.$dirty && !this.$v.form.pointValueAmountForInviteeApprovalType?.required)
  }

  get isValidPointAmountGeneral(): boolean | undefined {
    return ((this.$v.form.pointValueAmountForGeneral?.$dirty && !this.$v.form.pointValueAmountForGeneral?.required) || !this.$v.form.pointValueAmountForGeneral?.minValue) || (this.$v.form.pointValueAmountForGeneralDisburseTo?.$dirty && !this.$v.form.pointValueAmountForGeneralDisburseTo?.required) || (this.$v.form.pointValueAmountForGeneralApprovalType?.$dirty && !this.$v.form.pointValueAmountForGeneralApprovalType?.required)
  }

  get isValidPointValuePercentageInviter(): boolean | undefined {
    return ((this.$v.form.pointValuePercentageForInviter?.$dirty && !this.$v.form.pointValuePercentageForInviter?.required) || !this.$v.form.pointValuePercentageForInviter?.minValue) || ((this.$v.form.pointValuePercentageForInviterMax?.$dirty && !this.$v.form.pointValuePercentageForInviterMax?.required) || !this.$v.form.pointValuePercentageForInviterMax?.minValue) || (this.$v.form.pointValuePercentageForInviterDisburseTo?.$dirty && !this.$v.form.pointValuePercentageForInviterDisburseTo?.required) || (this.$v.form.pointValuePercentageForInviterApprovalType?.$dirty && !this.$v.form.pointValuePercentageForInviterApprovalType?.required)
  }

  get isValidPointValuePercentageInvitee(): boolean | undefined {
    return ((this.$v.form.pointValuePercentageForInvitee?.$dirty && !this.$v.form.pointValuePercentageForInvitee?.required) || !this.$v.form.pointValuePercentageForInvitee?.minValue) || ((this.$v.form.pointValuePercentageForInviteeMax?.$dirty && !this.$v.form.pointValuePercentageForInviteeMax?.required) || !this.$v.form.pointValuePercentageForInviteeMax?.minValue) || (this.$v.form.pointValuePercentageForInviteeDisburseTo?.$dirty && !this.$v.form.pointValuePercentageForInviteeDisburseTo?.required) || (this.$v.form.pointValuePercentageForInviteeApprovalType?.$dirty && !this.$v.form.pointValuePercentageForInviteeApprovalType?.required)
  }

  get isValidPointValuePercentageGeneral(): boolean | undefined {
    return ((this.$v.form.pointValuePercentageForGeneral?.$dirty && !this.$v.form.pointValuePercentageForGeneral?.required) || !this.$v.form.pointValuePercentageForGeneral?.minValue) || ((this.$v.form.pointValuePercentageForGeneralMax?.$dirty && !this.$v.form.pointValuePercentageForGeneralMax?.required) || !this.$v.form.pointValuePercentageForGeneralMax?.minValue) || (this.$v.form.pointValuePercentageForGeneralDisburseTo?.$dirty && !this.$v.form.pointValuePercentageForGeneralDisburseTo?.required) || (this.$v.form.pointValuePercentageForGeneralApprovalType?.$dirty && !this.$v.form.pointValuePercentageForGeneralApprovalType?.required)
  }

  get pointValuePercentageForInviterValidation(): boolean {
    if (this.pointValuePercentage) {
      if ((this.pointValuePercentageForInviterActive || this.pointValuePercentageForInviterMaxActive) && !this.pointValuePercentageForInviteeActive && !this.pointValuePercentageForGeneralActive) {
        return true
      } else if ((this.pointValuePercentageForInviterActive || this.pointValuePercentageForInviterMaxActive) && this.pointValuePercentageForInviteeActive && !this.pointValuePercentageForGeneralActive) {
        return true
      } else if (!this.pointValuePercentageForInviterActive && this.pointValuePercentageForInviteeActive && !this.pointValuePercentageForGeneralActive) {
        return false
      } else if (!this.pointValuePercentageForInviterActive && !this.pointValuePercentageForInviteeActive && this.pointValuePercentageForGeneralActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValuePercentageForInviteeValidation(): boolean {
    if (this.pointValuePercentage) {
      if ((this.pointValuePercentageForInviteeActive || this.pointValuePercentageForInviteeMaxActive) && !this.pointValuePercentageForInviterActive && !this.pointValuePercentageForGeneralActive) {
        return true
      } else if ((this.pointValuePercentageForInviteeActive || this.pointValuePercentageForInviteeMaxActive) && this.pointValuePercentageForInviterActive && !this.pointValuePercentageForGeneralActive) {
        return true
      } else if (!this.pointValuePercentageForInviteeActive && this.pointValuePercentageForInviterActive && !this.pointValuePercentageForGeneralActive) {
        return false
      } else if (!this.pointValuePercentageForInviteeActive && !this.pointValuePercentageForInviterActive && this.pointValuePercentageForGeneralActive) {
        return false
      }
      return true
    }
    return false
  }

  get pointValuePercentageForGeneralValidation(): boolean {
    if (this.pointValuePercentage) {
      if (this.pointValuePercentageForGeneralActive) {
        return true
      } else return false
    }
    return false
  }

  get pointPurposeValidation(): boolean {
    if (this.form.pointPurposeState.filter(item => item.checked).length) {
      return this.form.pointPurposeState.filter(item => item.checked).map(item => item.value > 0).includes(false)
    }
    return true
  }

  get pointValueAmount(): boolean {
    return this.form.pointValue?.value === constantData.PointValue.AMOUNT
  }

  get pointValuePercentage(): boolean {
    return this.form.pointValue?.value === constantData.PointValue.PERCENTAGE
  }

  get pointDisburseTypeDirectlyAfterTrigger(): boolean {
    return this.form.pointDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_TRIGGER
  }

  get pointDisburseTypeDirectlyAfterFirstTransaction(): boolean {
    return this.form.pointDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValueAmountForInviterDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValueAmountForInviterDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValueAmountForInviterDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValueAmountForInviterDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValueAmountForInviteeDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValueAmountForInviteeDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValueAmountForInviteeDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValueAmountForInviteeDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValueAmountForGeneralDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValueAmountForGeneralDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValueAmountForGeneralDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValueAmountForGeneralDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValuePercentageForInviterDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValuePercentageForInviterDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValuePercentageForInviterDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValuePercentageForInviterDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValuePercentageForInviteeDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValuePercentageForInviteeDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValuePercentageForInviteeDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValuePercentageForInviteeDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get pointValuePercentageForGeneralDisburseTypeSpecificDateEveryMonth(): boolean {
    return this.form.pointValuePercentageForGeneralDisburseType?.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
  }

  get pointValuePercentageForGeneralDisburseTypeAfterFirstTransaction(): boolean {
    return this.form.pointValuePercentageForGeneralDisburseType?.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
  }

  get isUserTypeAllLogin(): boolean {
    return this.form.userTarget?.value === constantData.UserType.LOGIN
  }

  get isUserTypeWithReferral(): boolean {
    return this.form.userTarget?.value === constantData.UserType.WITH_REFERRAL
  }

  get isUserTypeWithoutReferral(): boolean {
    return this.form.userTarget?.value === constantData.UserType.WITHOUT_REFERRAL
  }

  get isUserTypeSelectedUser(): boolean {
    return this.form.userTarget?.value === constantData.UserType.SELECTED
  }

  get triggedByTransactionDone(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.TRANSACTION_DONE
  }

  get triggedByRegistration(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.REGISTRATION
  }

  get triggedByReferralCodeUsed(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.REFERRAL_CODE_USED
  }

  get triggedByCompleteProfile(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.COMPLETE_PROFILE
  }

  get triggedByReferralInvitee(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.REFERRAL_INVITEE
  }

  get triggedByInvoicePaidAll(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.PAID_ALL
  }

  get triggedByShipmentPaidCity(): boolean {
    return (
      this.form.triggerBy?.value ===
      constantData.TriggerType.SHIPMENT_PAID_BY_CITY
    )
  }

  get isTriggeredByOnSchedule(): boolean {
    return this.form.triggerBy?.value === constantData.TriggerType.ON_SCHEDULE
  }

  get setBgClassSwitcherBudgetAmount(): string {
    return this.budgetAmountDisabledOnEdit && this.budgetAmountActive
      ? 'bg-gray-2'
      : 'bg-bgPrimary'
  }

  get setBgClassSwitcherExtraRules(): string {
    return this.extraRulesDisabledOnEdit && this.extraRulesActive
      ? 'bg-gray-2'
      : 'bg-bgPrimary'
  }

  get budgetAmountError(): string {
    if (
      (this.$v.form.budgetAmount?.$dirty &&
        !this.$v.form.budgetAmount?.required) ||
      !this.$v.form.budgetAmount?.minValue
    ) {
      return `Budget amount can’t be 0`
    }

    // if (
    //   this.form.budgetAmount &&
    //   this.$v.form.pointAmount?.$dirty &&
    //   +this.form.budgetAmount < this.form.pointAmount
    // ) {
    //   return 'Budget amount must be higher than the point amount'
    // }

    if (
      this.form.budgetAmount &&
      this.form.maxAmountGiven &&
      this.$v.form.maxAmountGiven?.$dirty &&
      +this.form.budgetAmount < this.form.maxAmountGiven
    ) {
      return 'Budget amount must be higher than the point amount'
    }

    return ''
  }

  private setSaldoPurposePayload(value: string): void {
    if (this.form.purpose.includes(value)) {
      this.form.purpose = this.form.purpose.filter(item => item !== value)
    } else {
      this.form.purpose.push(value)
    }
  }

  private setInvoiceSourcePayload(value: string): void {
    if (this.form.invoiceSource.includes(value)) {
      this.form.invoiceSource = this.form.invoiceSource.filter(
        item => item !== value
      )
    } else {
      this.form.invoiceSource.push(value)
    }
  }

  get isMemberUser(): boolean {
    return !!(this.form.userTarget && this.form.userTarget.value === constantData.UserType.MEMBERSHIP)
  }

  private setInvoiceSourcePayloadGroup(value: string): void {
    const group = this.getOptionsTriggeredInvoicePaidAll.find(
      source => source.group === value
    )
    if (
      group?.options?.every(
        option => !this.form.invoiceSource.includes(option.value)
      )
    ) {
      const groupOption: LabelValue<string>[] = group.options
      let newGroup = groupOption.map(option => option.value)

      if (this.isMemberUser) {
        newGroup = groupOption
          .filter(option => !option['disabled'])
          .map(option => option.value)
      }

      this.form.invoiceSource = [...this.form.invoiceSource, ...newGroup]
    } else {
      this.form.invoiceSource = this.form.invoiceSource.filter(
        item => !group?.options?.find(option => option.value === item)
      )
    }
  }

  private setPaymentMethodPayload(value: string): void {
    if (this.form.paymentMethod.includes(value)) {
      this.form.paymentMethod = this.form.paymentMethod.filter(
        item => item !== value
      )
    } else {
      this.form.paymentMethod.push(value)
    }
  }

  private onFileChange($event: File) {
    this.$v.form.fileUsers?.$touch()
    this.form.fileUsers = $event
  }

  private onCreateProgram(): void {
    this.$v.$touch()

    if (!this.$v.form.$invalid) {
      const form = {
        ...this.form,
        isPercentage:
          this.form.pointValueType === constantData.PointValueType.PERCENTAGE,
        isExactDate: this.form.pointExpirationType === 'exact-date',
      }
      if (this.getConditionForParcelPoint) {
        form.purpose = []
      }
      controller.sendProgram(form)
    } else {
      this.$notify({
        title: 'Create Program',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({name: 'ProgramaticPointPage'})
  }

  get disableStartDate(): boolean {
    if (!this.form.triggerBy) {
      return true
    }

    if (this.form.triggerBy.value === constantData.TriggerType.ON_SCHEDULE) {
      return true
    }

    return false
  }

  get shipmentStatusOptions(): DropdownInterface[] {
    return this.constants.SHIPMENT_STATUS.map(shipmentStatus => ({
      ...shipmentStatus,
    }))
  }

  get specificDateOptions(): DropdownInterface[] {
    return this.constants.SPECIFIC_DATE.map(specificDate => ({
      ...specificDate,
    }))
  }

  get targetOptionsNew(): DropdownInterface[] {
    return this.constants.TARGET_OPTIONS.map(target => ({
      ...target,
      $isDisabled: !this.useActionProgrammaticPoint.isParcelPointUserTarget(
        <constantData.UserType>target.value
      ),
    }))
  }

  get pointValueOptionsNew(): DropdownInterface[] {
    if (
      (
        this.triggedByRegistration ||
        this.triggedByReferralCodeUsed ||
        this.triggedByReferralInvitee
      ) &&
      this.pointDisburseTypeDirectlyAfterTrigger
    ) {
      return this.constants.POINT_VALUE.map(pointValue => ({
        ...pointValue,
        $isDisabled: pointValue.value === constantData.PointValue.PERCENTAGE
      }))
    } else if (this.triggedByCompleteProfile) {
      return this.constants.POINT_VALUE.map(pointValue => ({
        ...pointValue,
        $isDisabled: pointValue.value === constantData.PointValue.PERCENTAGE
      }))
    } else {
      return this.constants.POINT_VALUE.map(pointValue => ({
        ...pointValue,
      }))
    }
  }

  get InviterDisabled(): boolean {
    if (this.triggedByRegistration && (this.isUserTypeAllLogin || this.isUserTypeWithoutReferral)) {
      return true
    } else if (this.triggedByReferralInvitee && (this.isUserTypeAllLogin || this.isUserTypeWithReferral)) {
      return true
    } else if (this.triggedByCompleteProfile || this.isUserTypeSelectedUser || this.isMemberUser) {
      return true
    } else {
      return false
    }
  }

  get InviteeDisabled(): boolean {
    if (this.triggedByRegistration && (this.isUserTypeAllLogin || this.isUserTypeWithoutReferral)) {
      return true
    } else if (this.triggedByReferralCodeUsed && (this.isUserTypeAllLogin || this.isUserTypeWithReferral)) {
      return true
    } else if (this.triggedByCompleteProfile || this.isUserTypeSelectedUser || this.isMemberUser) {
      return true
    } else {
      return false
    }
  }

  get GeneralDisabled(): boolean {
    if (this.triggedByRegistration && this.isUserTypeWithReferral) {
      return true
    } else if (
      (this.triggedByReferralCodeUsed || this.triggedByReferralInvitee) &&
      (this.isUserTypeAllLogin || this.isUserTypeWithReferral)
    ) {
      return true
    } else {
      return false
    }
  }

  get disburseTypeOptionsNew(): DropdownInterface[] {
    if (
      this.triggedByRegistration ||
      this.triggedByReferralCodeUsed ||
      this.triggedByReferralInvitee
    ) {
      if (this.pointValuePercentage || (this.isUserTypeWithoutReferral && this.triggedByRegistration)) {
        return this.constants.POINT_DISBURSE_TYPE.map(disburseType => ({
          ...disburseType,
          $isDisabled: disburseType.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH || disburseType.value === constantData.PointDisburseType.DIRECTLY_AFTER_TRIGGER
        }))
      } else {
        return this.constants.POINT_DISBURSE_TYPE.map(disburseType => ({
          ...disburseType,
          $isDisabled: disburseType.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
        }))
      }
    } else if (this.isUserTypeSelectedUser || this.isMemberUser) {
      return this.constants.POINT_DISBURSE_TYPE.map(disburseType => ({
        ...disburseType,
        $isDisabled: disburseType.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION
      }))
    } else if (this.triggedByCompleteProfile) {
      return this.constants.POINT_DISBURSE_TYPE.map(disburseType => ({
        ...disburseType,
        $isDisabled: disburseType.value === constantData.PointDisburseType.DIRECTLY_AFTER_FIRST_TRANSACTION || disburseType.value === constantData.PointDisburseType.SPECIFIC_DATE_EVERY_MONTH
      }))
    } else {
      return this.constants.POINT_DISBURSE_TYPE.map(disburseType => ({
        ...disburseType,
      }))
    }
  }

  get triggerParcelPoint(): boolean {
    return (
      this.form.triggerBy !== null &&
      this.useActionProgrammaticPoint.isParcelPointTrigger(
        <constantData.TriggerType>this.form.triggerBy.value
      )
    )
  }

  private onChangeBudgetToggle(): void {
    this.budgetAmountActive = !this.budgetAmountActive
    this.form.budgetAmount = null
  }

  private onChangeExtraRulesToggle(): void {
    this.extraRulesActive = !this.extraRulesActive
    this.form.extraRulesActive = !this.form.extraRulesActive
    this.totalAmountTransactionActive = false
    this.form.totalAmountTransaction = null
    this.form.totalAmountTransactionTime = null
    this.totalNumberTransactionActive = false
    this.form.totalNumberTransaction = null
    this.form.totalNumberTransactionTime = null
    this.form.logicType = ''
  }

  private onChangeToggleMaxValidateDeviceId(): void {
    this.deviceIdLimitCustomerActive = !this.deviceIdLimitCustomerActive
    this.form.deviceIdLimitCustomer = null
  }

  private onChangeTotalAmountTransactionToggle(): void {
    this.totalAmountTransactionActive = !this.totalAmountTransactionActive
    this.form.totalAmountTransaction = null
    this.form.totalAmountTransactionTime = null
    this.form.logicType = ''
  }

  private onChangeTotalNumberTransactionToggle(): void {
    this.totalNumberTransactionActive = !this.totalNumberTransactionActive
    this.form.totalNumberTransaction = null
    this.form.totalNumberTransactionTime = null
    this.form.logicType = ''
  }

  private onChangePointValueAmountForInviterToggle(): void {
    this.pointValueAmountForInviterActive = !this.pointValueAmountForInviterActive
    this.form.pointValueAmountForInviter = null
    this.form.pointValueAmountForInviterDisburseTo = null
    this.form.pointValueAmountForInviterApprovalType = null
    this.form.pointValueAmountForInviterDisburseType = null
    this.form.pointValueAmountForInviterDisburseTypeShipmentStatus = null
    this.form.pointValueAmountForInviterDisburseTypeSpecificDate = null
  }

  private onChangePointValueAmountForInviteeToggle(): void {
    this.pointValueAmountForInviteeActive = !this.pointValueAmountForInviteeActive
    this.form.pointValueAmountForInvitee = null
    this.form.pointValueAmountForInviteeDisburseTo = null
    this.form.pointValueAmountForInviteeApprovalType = null
    this.form.pointValueAmountForInviteeDisburseType = null
    this.form.pointValueAmountForInviteeDisburseTypeShipmentStatus = null
    this.form.pointValueAmountForInviteeDisburseTypeSpecificDate = null
  }

  private onChangePointValueAmountForGeneralToggle(): void {
    this.pointValueAmountForGeneralActive = !this.pointValueAmountForGeneralActive
    this.form.pointValueAmountForGeneral = null
    this.form.pointValueAmountForGeneralDisburseTo = null
    this.form.pointValueAmountForGeneralApprovalType = null
    this.form.pointValueAmountForGeneralDisburseType = null
    this.form.pointValueAmountForGeneralDisburseTypeShipmentStatus = null
    this.form.pointValueAmountForGeneralDisburseTypeSpecificDate = null
  }

  private onChangePointValuePercentageForInviterToggle(): void {
    this.pointValuePercentageForInviterActive = !this.pointValuePercentageForInviterActive
    this.form.pointValuePercentageForInviter = null
    this.form.pointValuePercentageForInviterDisburseTo = null
    this.form.pointValuePercentageForInviterApprovalType = null
    this.form.pointValuePercentageForGeneralDisburseType = null
    this.form.pointValuePercentageForGeneralDisburseTypeShipmentStatus = null
    this.form.pointValuePercentageForGeneralDisburseTypeSpecificDate = null
  }

  private onChangePointValuePercentageForInviterMaxToggle(): void {
    this.pointValuePercentageForInviterMaxActive = !this.pointValuePercentageForInviterMaxActive
    this.form.pointValuePercentageForInviterMax = null
  }

  private onChangePointValuePercentageForInviteeToggle(): void {
    this.pointValuePercentageForInviteeActive = !this.pointValuePercentageForInviteeActive
    this.form.pointValuePercentageForInvitee = null
    this.form.pointValuePercentageForInviteeDisburseTo = null
    this.form.pointValuePercentageForInviteeApprovalType = null
    this.form.pointValuePercentageForGeneralDisburseType = null
    this.form.pointValuePercentageForGeneralDisburseTypeShipmentStatus = null
    this.form.pointValuePercentageForGeneralDisburseTypeSpecificDate = null
  }

  private onChangePointValuePercentageForInviteeMaxToggle(): void {
    this.pointValuePercentageForInviteeMaxActive = !this.pointValuePercentageForInviteeMaxActive
    this.form.pointValuePercentageForInviteeMax = null
  }

  private onChangePointValuePercentageForGeneralToggle(): void {
    this.pointValuePercentageForGeneralActive = !this.pointValuePercentageForGeneralActive
    this.form.pointValuePercentageForGeneral = null
    this.form.pointValuePercentageForGeneralDisburseTo = null
    this.form.pointValuePercentageForGeneralApprovalType = null
    this.form.pointValuePercentageForGeneralDisburseType = null
    this.form.pointValuePercentageForGeneralDisburseTypeShipmentStatus = null
    this.form.pointValuePercentageForGeneralDisburseTypeSpecificDate = null
  }

  private onChangePointValuePercentageForGeneralMaxToggle(): void {
    this.pointValuePercentageForGeneralMaxActive = !this.pointValuePercentageForGeneralMaxActive
    this.form.pointValuePercentageForGeneralMax = null
  }

  private resetConditionAffectByTrigger(): void {
    this.pointValueAmountForInviterActive = false
    this.pointValueAmountForInviteeActive = false
    this.pointValueAmountForGeneralActive = false
    this.pointValuePercentageForInviterActive = false
    this.pointValuePercentageForInviterMaxActive = false
    this.pointValuePercentageForInviteeActive = false
    this.pointValuePercentageForInviteeMaxActive = false
    this.pointValuePercentageForGeneralActive = false
    this.pointValuePercentageForGeneralMaxActive = false
    this.form.pointValueAmountForInviter = null
    this.form.pointValueAmountForInviterDisburseTo = null
    this.form.pointValueAmountForInviterApprovalType = null
    this.form.pointValueAmountForInvitee = null
    this.form.pointValueAmountForInviteeDisburseTo = null
    this.form.pointValueAmountForInviteeApprovalType = null
    this.form.pointValueAmountForGeneral = null
    this.form.pointValueAmountForGeneralDisburseTo = null
    this.form.pointValueAmountForGeneralApprovalType = null
    this.form.pointValuePercentageForInviter = null
    this.form.pointValuePercentageForInviterMax = null
    this.form.pointValuePercentageForInvitee = null
    this.form.pointValuePercentageForInviteeMax = null
    this.form.pointValuePercentageForGeneral = null
    this.form.pointValuePercentageForGeneralMax = null
    this.form.pointValue = null
    this.form.pointDisburseType = null
    this.form.shipmentStatus = null
    this.form.specificDate = null
    this.extraRulesActive = false
    this.form.extraRulesActive = false
    this.totalAmountTransactionActive = false
    this.form.totalAmountTransaction = null
    this.form.totalAmountTransactionTime = null
    this.totalNumberTransactionActive = false
    this.form.totalNumberTransaction = null
    this.form.totalNumberTransactionTime = null
    this.form.logicType = ''
  }

  private resetOnChangedPointValue(): void {
    this.pointValueAmountForInviterActive = false
    this.pointValueAmountForInviteeActive = false
    this.pointValueAmountForGeneralActive = false
    this.pointValuePercentageForInviterActive = false
    this.pointValuePercentageForInviterMaxActive = false
    this.pointValuePercentageForInviteeActive = false
    this.pointValuePercentageForInviteeMaxActive = false
    this.pointValuePercentageForGeneralActive = false
    this.pointValuePercentageForGeneralMaxActive = false
    this.form.pointValueAmountForInviter = null
    this.form.pointValueAmountForInviterDisburseTo = null
    this.form.pointValueAmountForInviterApprovalType = null
    this.form.pointValueAmountForInvitee = null
    this.form.pointValueAmountForInviteeDisburseTo = null
    this.form.pointValueAmountForInviteeApprovalType = null
    this.form.pointValueAmountForGeneral = null
    this.form.pointValueAmountForGeneralDisburseTo = null
    this.form.pointValueAmountForGeneralApprovalType = null
    this.form.pointValuePercentageForInviter = null
    this.form.pointValuePercentageForInviterMax = null
    this.form.pointValuePercentageForInviterDisburseTo = null
    this.form.pointValuePercentageForInviterApprovalType = null
    this.form.pointValuePercentageForInvitee = null
    this.form.pointValuePercentageForInviteeMax = null
    this.form.pointValuePercentageForInviteeDisburseTo = null
    this.form.pointValuePercentageForInviteeApprovalType = null
    this.form.pointValuePercentageForGeneral = null
    this.form.pointValuePercentageForGeneralMax = null
    this.form.pointValuePercentageForGeneralDisburseTo = null
    this.form.pointValuePercentageForGeneralApprovalType = null
  }

  private resetOnChangedDisburseType(): void {
    this.form.shipmentStatus = null
    this.form.specificDate = null
  }

  private resetOnChangedDisburseProgram(): void {
    this.totalNumberDisbursePerProgramActive = false
    this.form.totalNumberDisbursePerProgram = null
    this.totalAmountDisbursePerProgramActive = false
    this.form.totalAmountDisbursePerProgram = null
  }

  private resetOnChangedDisburseUser(): void {
    this.totalNumberDisbursePerUserActive = false
    this.form.totalNumberDisbursePerUser = null
    this.totalAmountDisbursePerUserActive = false
    this.form.totalAmountDisbursePerUser = null
  }

  private onChangeTotalNumberDisbursePerProgramToggle(): void {
    this.totalNumberDisbursePerProgramActive = !this.totalNumberDisbursePerProgramActive
    this.form.totalNumberDisbursePerProgram = null
  }

  private onChangeTotalAmountDisbursePerProgramToggle(): void {
    this.totalAmountDisbursePerProgramActive = !this.totalAmountDisbursePerProgramActive
    this.form.totalAmountDisbursePerProgram = null
  }

  private onChangeTotalNumberDisbursePerUserToggle(): void {
    this.totalNumberDisbursePerUserActive = !this.totalNumberDisbursePerUserActive
    this.form.totalNumberDisbursePerUser = null
  }

  private onChangeTotalAmountDisbursePerUserToggle(): void {
    this.totalAmountDisbursePerUserActive = !this.totalAmountDisbursePerUserActive
    this.form.totalAmountDisbursePerUser = null
  }

  private onChangePointPurposeStateToggle(index: number): void {
    this.form.pointPurposeState[index].checked = !this.form.pointPurposeState[index].checked
    this.form.pointPurposeState[index].value = this.form.pointPurposeState[index].checked ? 100 : 0
    this.pointPurposeChanged = true
  }

  private purposeLabel(purpose: { value: string, label: string }): string {
    switch (purpose.value) {
      case 'PICKUP':
        return 'Pick Up'
      case 'DROPOFF':
        return 'Drop Paket'
      case 'SHOP':
        return 'BisaBelanja'
      case 'PICKUP_FAVORITE':
        return 'Pick Up Favorite'
      case 'DROPOFF_FAVORITE':
        return 'Drop Off Favorite'
      case 'REDEEM_VOUCHER':
        return 'Redeem Voucher'
      default:
        return purpose.label
    }
  }

  private onCancel(): void {
    this.showModalConfirm = true
  }

  private goBack(): void {
    this.showModalConfirm = false
  }

  private doActionConfirm(): void {
    this.$router.push('/saldo')
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.SAVE_PROGRAM_SUCCESS)
  }
}
